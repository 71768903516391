@import "../../../src/scss/variable.scss";
.role-form {
    &__header {
        font-size: 16px;
        font-weight: 700;
    }

    &__userInfo {
        font-size: 14px;
        color: $paleGrey;
    }

    &--btn {
        width: 180px !important;
    }
}

.add-product {
    &__dustbin-icon {
        margin-right: 14px;
    }

    &__item-label {
        font-weight: 600;
        font-size: 21px;
        line-height: 23px;
    }
}

// .css-1fdsijx-ValueContainer {
//     height: 48px;
//     .add-branch {
//         &__loading-button {
//             margin-left: 0;
//             width: 180px;
//         }

//         &__toggle {
//             height: 50px;
//         }
//     }
// }

.add-branch {
    &__manageLicenseBtns {
        margin-top: 46px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;

        & > button {
            width: 34% !important;

            &:first-child {
                width: 24% !important;
            }
        }
    }

    &__copyIcon {
        right: 10px;
        top: 35px;
        position: absolute;
        width: 60px;
        height: 34px;
        cursor: pointer;
    }

    &__licenseKeyField {
        position: relative;
    }

    &__loading-button {
        width: 160px;
    }
}
.item-form {
    &--btn {
        margin-top: 26px;
        margin-left: 8px;
        min-width: 174px;
    }
    &--label {
        top: 40px;
        position: absolute;
        right: 22px;
    }
    &--colContainer {
        position: relative;
    }
    &--maxQuantity {
        label:first-child {
            visibility: hidden;
        }
    }
    &--mt40 {
        margin-top: 40px;
    }
    &--font500 {
        font-weight: 500;
        font-size: 20px;
    }
    &--toLabel {
        right: -9px;
    }
    &--borderBottom {
        border-bottom: 0.5px groove $borderColor;
    }
    &--cycle {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        margin-top: 40px;
    }
}

.users-form {
    .loading-button {
        width: 165px;
    }
}

input[type="date" i] {
    font-size: 24px;
}

.bulk-checkbox {
    margin-right: 25%;
}
